import React from 'react'
import { Translate } from "react-localize-redux"

import * as Checker from '../../utils/checker'


const AlarmClock = (props) => {

	const {alarm_level, alarm_stopped, last_safe_time} = props

  let hoursString = last_safe_time ? ("0" + (new Date(last_safe_time)).getHours()).slice(-2) : "--"
  let minutesString = last_safe_time ? ("0" + (new Date(last_safe_time)).getMinutes()).slice(-2) : "--"

	return (
      <div className={"fc-ph-24 fc-pb-8 fc-fs-12 fc-emph2 fc-color-alarm-"+Checker.isActiveAlarm(alarm_level, alarm_stopped)}>
        { Checker.isActiveAlarm(alarm_level, alarm_stopped) ?
    		    <span><Translate id="map.alarminfo"/>&nbsp;{hoursString}:{minutesString}&nbsp;&nbsp;</span> :
            <span><Translate id="map.alarmdeactivated"/>&nbsp;&nbsp;</span>
        }
        <img className={"fc-clock"} src={"/img/clock-alarm-"+Checker.isActiveAlarm(alarm_level, alarm_stopped)+".svg"} alt="Alarm clock"/>
    	</div>
  	)
}

export default AlarmClock