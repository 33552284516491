import React from 'react'
import { Translate } from "react-localize-redux";

import * as Checker from '../../utils/checker'
import useReverseGeocoding from '../../utils/useReverseGeocoding'

import MapComponent from '../../components/MapComponent'

const MapContainer = (props) => {

  const {position_data, alarm_level, alarm_reason} = props
  const address = useReverseGeocoding(position_data)

  {/*console.log("MapContainer", position_data, alarm_level, alarm_reason)*/}

  return (
    <div className="fc-map-wrapper fc-bg-lightblue">
      <div className="fc-ph-24 fc-pb-24">
        <div className="fc-pt-8 fc-fs-12">
          { Checker.isValidAndRecentPosition(position_data) ? 
            [6, 7, 14].indexOf(alarm_reason) > -1 ? <Translate id="map.position.intro.car_only"/> : <Translate id="map.position.intro.car_and_baby"/> :
            <Translate id="map.position.intro.not_available"/>
          }
        </div>
        <div className="fc-pt-8 fc-emph2 fc-black">
          {
            Checker.isValidAndRecentPosition(position_data) ?
            address ? address : <Translate id="map.position.info.pinned"></Translate> :
            "--"
          }
        </div>
      </div>
      { Checker.isValidAndRecentPosition(position_data) ?
        <MapComponent className="fc-pv-8" alarm_level={alarm_level} position={position_data}/> :
        <div className="fc-no-map"></div>
      }  
    </div>
  )
}

export default MapContainer