import detectBrowserLanguage from 'detect-browser-language'
import axios from 'axios'

import stubStopAlarmResponse from '../utils/stubStopAlarmResponse'


export function getTimestampWithMillisecs(timestamp) {
  timestamp = parseInt(timestamp)
  if (timestamp < 1561000000000) {
    return timestamp * 1000
  }
  return timestamp;
}

export const languageList =   [
                                { name: "Italian", code: "it" },
                                { name: "English", code: "en" } 
                              ]

export const langList = languageList.map( (elem) => { return elem.code } )


export function getBrowserLangOrDefault() {
  return langList.indexOf(getBrowserLang()) === -1 ? getDefaultLang() : getBrowserLang();
}

export function getBrowserLang() {
  try {
    console.log("detectBrowserLanguage: ", detectBrowserLanguage())
    return detectBrowserLanguage().split("-")[0]
  } catch (e) {
    console.log(e);
    return getDefaultLang();
  }
}

export function getDefaultLang() {
  return langList[0];
}

export function getEnvironment(web_token) {
  // Dev, Stage, Prod
  console.log("Analizing: " + web_token)
  if ( web_token.substring(0,1) === 'd') {
    console.log("returning Dev");
    return "Dev";
  }
  if ( web_token.substring(0,1) === 's') {
    console.log("returning Stage");
    return "Stage";
  }
  if ( web_token.substring(0,1) === 'e') {
    console.log("returning Expo");
    return "Expo";
  }
  if ( web_token.substring(0,1) === 'p') {
    console.log("returning Prod");
    return "Prod";
  }
  console.log("returning default: Dev");
  return "Dev";
}

export async function performTataBrainRequest(env, body_data) {
  const response = await axios({
          url: "https://3euiei60al.execute-api.eu-central-1.amazonaws.com/" + env + "/processupdate",
          method: 'POST',
          headers: {
            'x-api-key' : 'pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x',
            'content-type' : 'application/json'
          },
          data: body_data
        })
  return response
}

export async function stopAlarmTatabrain(info, web_token) {

      if ( stubStopAlarmResponse[web_token.substring(1)] ) {
        const response = stubStopAlarmResponse[web_token.substring(1)]
        // Updating info with stub
        if (response.data && !response.data.error ) {
          return {
              loading:false,
              web_token:web_token,
              info:response.data
          }
        }
        if (response.error || response.data.error ) {
            return {
              loading:false,
              web_token:web_token,
              error: response.error ? response.error : response.data.error
            }            
        }

        return {
          loading:false,
          web_token:web_token,
          error: "Generic error - invalid response"
        } 
      }

      const status = {
          device_id : info.device_id,
          current_user : info.callee_id,
          actor : info.callee_id,
          web_token : web_token
      }
      const body_data = {
          action: "stop_alarm",
          status: status
      }
      const env = getEnvironment(web_token);

      try {
          let response = await performTataBrainRequest(env, body_data)

          // Updating info with error
          if (response.error || response.data.error ) {
              return {
                loading:false,
                web_token:web_token,
                error: response.error ? response.error : response.data.error
              }            
          }

          // Updating info with real info
          return {
            loading:false,
            info:response.data,
            web_token:web_token
          }

      } catch (error) {
          console.log('retrieveTataInfo error value', error)
          // Updating info with error
          return {
            loading:false,
            web_token:web_token,
            error: error
          }
      }
    }