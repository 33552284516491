import React from 'react';
import * as Utils from '../utils/utils'

export function isValidAndRecentPosition(position_data) {
    return  isValidPosition(position_data) &&
            (
              Utils.getTimestampWithMillisecs(position_data.lastUpdate) > (parseInt(Date.now()) - 2*60*60*1000) ||
              Utils.getTimestampWithMillisecs(position_data.timestamp) > (parseInt(Date.now()) - 2*60*60*1000)
            )
  }

export function isValidPosition(position_data) {
  return  position_data &&
          position_data.latitude &&
          position_data.longitude &&
          position_data.accuracy &&
          (position_data.lastUpdate || position_data.timestamp)
}

export function isActiveAlarm(alarm_level, alarm_stopped) {
    return alarm_level > 0 && alarm_stopped !== true; 
  }