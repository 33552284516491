import { useState, useEffect } from 'react';
import axios from 'axios'
import stubTataInfo from '../utils/stubTataInfo'
import * as Utils from '../utils/utils'

const useTataInfo = (web_token) => {
  const [info, setInfo] = useState(null);

  useEffect(() => {

    let timer1 = true

    async function retrieveTataInfo(web_token) {

      const body_data = {
        action: "get_stop_alarm_web_info",
        status: {"web_token": web_token}
      }

      const env = Utils.getEnvironment(web_token);

      if ( stubTataInfo[web_token.substring(1)] ) {
        const response = stubTataInfo[web_token.substring(1)]
        // Updating info with stub
        timer1 = setTimeout( () => { 
          setInfo(
            {
              loading:false,
              info:response.data.data,
              web_token:web_token
            }
            )
          }, 250)
        return true
      }
      
      try {
        let response = await Utils.performTataBrainRequest(env, body_data)

        // Updating info with error
        if (response.error || response.data.error ) {
            setInfo({
              loading:false,
              web_token:web_token,
              error: response.error ? response.error : response.data.error
            }
            );
            return false
        }

        // Updating info with real info
        setInfo(
          {
            loading:false,
            info:response.data.data,
            web_token:web_token
          }
        );

        return true
      } catch (error) {
          console.log('retrieveTataInfo error value', error)
          // Updating info with error
          setInfo({
            loading:false,
            web_token:web_token,
            error: error
          });
      }
    }
    retrieveTataInfo(web_token)

    return () => clearTimeout(timer1)
  }
  , [web_token])

  return info // the return of useState
}

export default useTataInfo