import React, { Component } from 'react';

import LanguageSelector from '../../components/LanguageSelector';


const Footer = () => (
  <div className="fc-navigation fc-header">
    <div className="fc-logo-wrapper"><img className="fc-logo" src="/img/tata_logo_w.png" alt="tata" title="tata logo"/></div>
  </div>
);

export default Footer; 
