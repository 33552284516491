import { useState, useEffect } from 'react';
import Geocode from "react-geocode";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyDgFl8bN5Qmcf4bLbmDdUo10-dTy2G4YN0");
Geocode.enableDebug();

const useReverseGeocoding = (position_data) => {
  const [address, setAddress] = useState(undefined);

  useEffect(() => {
    async function retrieveFormattedAddress(position_data) {
      
      try {
        const response = await Geocode.fromLatLng(position_data.latitude, position_data.longitude)
        // Updating formatted address with retrieved data
        const addressComponents = response.results[1].address_components
        console.log(response.results[1].formatted_address)
        setAddress(addressComponents[1].long_name + " " + addressComponents[0].long_name + ", " + addressComponents[3].long_name)        
        return true
      } catch (error) {
        console.log('retrieveFormattedAddress error value', error)
        // Updating formatted address  with error
        setAddress("---")                
      }
    }
    retrieveFormattedAddress(position_data)
    /* return () => {} */
  }
  , [position_data])

  return address // the return of useState
}

export default useReverseGeocoding