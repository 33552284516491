import React, { useState, useEffect } from 'react';
import { Translate } from "react-localize-redux"

import * as Utils from '../../utils/utils'
import useTataInfo from '../../utils/useTataInfo'
import useStopAlarmInfo from '../../utils/useStopAlarmInfo'

import Loader from "../../components/Loader"
import TataPuppet from "../../components/TataPuppet"
import InfoTitle from "../../components/InfoTitle"
import IntroSection from "../../components/IntroSection"
import AlarmClock from "../../components/AlarmClock"
import MapContainer from "../../components/MapContainer"
import ButtonContainer from "../../components/ButtonContainer"

const StopAlarmPage = (props) => {
	
	const web_token = props.match.params.slug
	const fetchInfo = useTataInfo(web_token)
	const info = fetchInfo ? fetchInfo.info : undefined
	const [stopAlarmInfo, setStopAlarmInfo] = useState({loading:false})

	const stopAlarmClicked = async () => {
		setStopAlarmInfo({loading:true})
		const stopAlarmResponse = await Utils.stopAlarmTatabrain(info, web_token)
		console.log("stopAlarmResponse: ", stopAlarmResponse)
		setStopAlarmInfo(stopAlarmResponse)		
    }

	if (!fetchInfo) {
	 	return (
		 	<div className="fc-page">
				<div className="fc-content fc-lh-16">
			  		<Loader type="text"/>
				</div>
		  	</div>
	  	)
	}

	if (stopAlarmInfo && (stopAlarmInfo.info || stopAlarmInfo.error)) { // it means the alarm is stopped TODO - check with Tatabrain logic
	 	return (
			<div className="fc-page">
				<div className="fc-content fc-lh-16">
					{ stopAlarmInfo.info ?
						<TataPuppet type="ok"/> :
						<TataPuppet type="warning"/>
					}
		 			<div className={"fc-intro__w90 fc-p-24"}>
		 				{ stopAlarmInfo.info ?
							<Translate>
					  			{({ translate }) => <InfoTitle text={translate("context.emergency_contact")}/>}
					  		</Translate> :
					  		<Translate>
					  			{({ translate }) => <InfoTitle text={translate("context.ops")}/>}
					  		</Translate>
					  	}
				  		<IntroSection info={fetchInfo.info} stopAlarmInfo={stopAlarmInfo}/>
				  	</div>				  	
				  	{fetchInfo.info &&
				  		<div className="fc-bg-lightblue fc-pt-24">		  	
				  			{fetchInfo.info && <AlarmClock alarm_level={fetchInfo.info.alarm_level} alarm_stopped={ stopAlarmInfo.info ? true : false } last_safe_time={fetchInfo.info.last_safe_time}/>}				  	
				  			{fetchInfo.info && <MapContainer position_data={fetchInfo.info.position_data} alarm_level={fetchInfo.info.alarm_level} alarm_reason={fetchInfo.info.alarm_reason} />}
				  		</div>
				  	}
				  	{fetchInfo.info.alarm_level > 0 && stopAlarmInfo.error && <ButtonContainer info={fetchInfo.info} stopAlarmInfo={stopAlarmInfo} buttonClicked={stopAlarmClicked}/>}
				</div>
			</div>
	  	)
	}

	if (fetchInfo && fetchInfo.info) {
	 	return (
			<div className="fc-page">
				<div className="fc-content fc-lh-16">
					{ fetchInfo.info.alarm_level > 0 ?
						<TataPuppet type="alarm"/> :
						<TataPuppet type="ok"/>					
					}
		 			<div className={"fc-intro__w90 fc-p-24"}>
						<Translate>
				  			{({ translate }) => <InfoTitle text={translate("context.emergency_contact")}/>}
				  		</Translate>
				  		<IntroSection info={fetchInfo.info}/>
				  	</div>				  	
				  	<div className={fetchInfo.info.alarm_level > 0 ? "fc-bg-lightblue fc-pt-16" : "fc-bg-lightblue fc-pt-16 fc-pb-8"}>		  	
				  		{fetchInfo.info && <AlarmClock alarm_level={fetchInfo.info.alarm_level} alarm_stopped={false} last_safe_time={fetchInfo.info.last_safe_time}/>}				  	
						{fetchInfo.info.alarm_level > 0 && <MapContainer position_data={fetchInfo.info.position_data} alarm_level={fetchInfo.info.alarm_level} alarm_reason={fetchInfo.info.alarm_reason} />}
				  	</div>
				  	{fetchInfo.info.alarm_level > 0 && <ButtonContainer info={fetchInfo.info} stopAlarmInfo={stopAlarmInfo} buttonClicked={stopAlarmClicked}/>}
				</div>
			</div>
	  	)
	}

	if (fetchInfo && fetchInfo.error) {
	 	return (
			<div className="fc-page">
				<div className="fc-content fc-lh-16">
					<TataPuppet type="warning"/>
		 			<div className={"fc-intro__w90 fc-p-24"}>
		                <Translate>
		                  {({ translate }) => <InfoTitle text={translate("context.emergency_contact")}/>}
		                </Translate>
		                <p>
		                  <Translate id="greetings.Ciao2"/>
		                </p>
		                <p>
		                  <Translate id="greetings.inactivelink"/>
		                </p>
		 			</div>
		 			{ fetchInfo.error && fetchInfo.error.code !== 404 &&
		                <div className={"fc-ph-24"}>
			                <div className={"fc-error fc-p-16"}>
			                    {fetchInfo.error.message}
			                </div>
		                </div>
		            }
				</div>
			</div>
	  	)
	}
	return JSON.stringify(fetchInfo)
}

export default StopAlarmPage
