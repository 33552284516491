import React from 'react';
import { Translate } from "react-localize-redux";

export function getCalleeName(info) {
     return info.callee_data.username ? " "+info.callee_data.username : "";
}

export function getNotifierName(info, useTranslate, customDefault) {
    return info && info.notifier_data && info.notifier_data.username ?
    info.notifier_data.username :
    useTranslate === true ? <Translate id={customDefault}/> : customDefault
}