import React, { Component, Fragment} from 'react';
import { Translate } from "react-localize-redux";
import * as Getter from "../../utils/getter.js";
import * as Checker from "../../utils/checker.js";

const getStatusDescriptionWithReason = (info, stopAlarmInfo) => {

    if (info && stopAlarmInfo && stopAlarmInfo.info) {
      console.log("alarm_reason: stopped --> OK")
      return (
        <div>
          <p>
            <Translate id="alarmStopped.ok"/>
          </p>
        </div>
      )
    } else if (info && stopAlarmInfo && stopAlarmInfo.error) {
      console.log("alarm_reason: stopped --> ERROR")
      return (
        <div>
          <p>
            <Translate id="alarmStopped.ko.something_wrong"/><br/>
            <Translate id="alarmStopped.ko.alarm_still_active"/><br/>
            <Translate id="alarmStopped.ko.try_again"/>
          </p>
        </div>
      )
    } else if (info && info.alarm_level && info.alarm_level <= -1) {
      console.log("alarm_reason: stopped --> OK")
      return (
        <div>
          <p>
            <Translate id="alarmStopped.someone_else.info"/>
      		{	Getter.getNotifierName(info) ?
        			<span> <Translate id="alarmStopped.someone_else.info_of"/> <span className="fc-emph2 fc-black">{Getter.getNotifierName(info)}</span>.</span> :
        			"."
			}
          </p>
          <p>
            <Translate id="alarmStopped.someone_else.thanks"/>
          </p>
        </div>
      )
    } else if ([4,5,12,13, "bluetooth"].includes(info.alarm_reason)) {
      console.log("alarm_reason: " + info.alarm_reason + " --> Bluetooth")
      return (
        <div>
          <p>
            <Translate id="alarm.intro_position"/>
          </p>
          <p>
            <Translate id="alarm.try_contact"/> <span className="fc-emph2 fc-black">{Getter.getNotifierName(info, true, "user._3")}</span>
            <Translate id="alarm.cta"/>
          </p>
          <p>
            <Translate id="alarm.thankyou"/> 
          </p>
        </div>
      )
    } else if ([6,14, "motion"].includes(info.alarm_reason)) {
      console.log("alarm_reason: " + info.alarm_reason + " --> Motion")
      return (
        <div>
          <p><Translate id="alarm.intro_position"/></p>
          <p>
            <Translate id="alarm.reason_motion.intro"/><span className="fc-emph2 fc-black">{Getter.getNotifierName(info, true, "user._3")}</span>&nbsp;
            <Translate id="alarm.reason_motion.child_in_car"/> 
          </p>
          <p>
            <Translate id="alarm.try_contact"/> <span className="fc-emph2 fc-black">{Getter.getNotifierName(info, true, "user._3")}</span>
            <Translate id="alarm.cta"/> 
          </p>
          <p>
            <Translate id="alarm.thankyou"/> 
          </p>
        </div>
      )
    } else if ([7, "network"].includes(info.alarm_reason) && Checker.isValidAndRecentPosition(info.position_data)) {
      console.log("alarm_reason: " + info.alarm_reason + " --> LastUpdate")

      return  (
        <div>
          <p>
            <Translate id="alarm.intro_position_last_update"/>
            <span className="fc-emph2 fc-black">{Getter.getNotifierName(info, true, "user._4")}</span>
           	<Translate id="alarm.intro_position_last_update_user"/>
          </p>
          <p>
            <Translate id="alarm.reason_lastUpdate.description_1"/> <span className="fc-emph2 fc-black">{Getter.getNotifierName(info, true, "user._3")}</span>
            <Translate id="alarm.reason_lastUpdate.description_2"/>
          </p>
          <p>
            <Translate id="alarm.try_contact"/> <span className="fc-emph2 fc-black">{Getter.getNotifierName(info, true, "user._3")}</span>
            <Translate id="alarm.cta"/> 
          </p>
          <p>
            <Translate id="alarm.thankyou"/> 
          </p>
        </div>
      )
    } else if ([7].includes(info.alarm_reason) && !Checker.isValidAndRecentPosition(info.position_data)) {
      console.log("alarm_reason: " + info.alarm_reason + " --> LastUpdate no position")

      return  (
        <div>
          <p>
            { Getter.getNotifierName(info) ?
              <Translate id="alarm.intro_no_position_last_update" data={{ notifierName: Getter.getNotifierName(info) }}/> :
              <Translate id="alarm.intro_no_position_last_update_no_notifier"/>
            }
          </p>
          <p>
            <Translate id="alarm.try_contact"/> <span className="fc-emph2 fc-black">{Getter.getNotifierName(info, true, "user._3")}</span>
            <Translate id="alarm.cta"/> 
          </p>
        </div>
      )
    }
    console.log("Default info.alarm_reason: " + info.alarm_reason)
    return  <div className="fc-mv-8">
              <Translate id="alarm._2_reason_default"/> <Translate id="alarm._3_reason_default"/> <span className="fc-emph2 fc-black">{Getter.getNotifierName(info, true, "user._4")}</span>. <Translate id="alarm._4_reason_default"/>
            </div>
}


const IntroSection = (props) => {
    const { info, stopAlarmInfo } = props
    const name = Getter.getCalleeName(info);

    return (
    	<div>
	    	<p>
	        	{ stopAlarmInfo ?
	        		<span><Translate id="greetings.thankyou"></Translate><span className="fc-emph2 fc-black">{name}</span>,</span> :
	        		<span><Translate id="greetings.Ciao"></Translate><span className="fc-emph2 fc-black">{name}</span>,</span>
	        	}
	    	</p>
	    	{getStatusDescriptionWithReason(info, stopAlarmInfo)}
	    </div>
    )
}

export default IntroSection