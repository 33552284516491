// global google
import React from 'react'
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps"
import { Marker } from "react-google-maps"

const MapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDgFl8bN5Qmcf4bLbmDdUo10-dTy2G4YN0&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `240px` }} />,
    containerElement: <div style={{ height: `240px` }} />,
    mapElement: <div className="map-element" style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)( (props) => {

  function getCenter() {
    return { lat: props.position.latitude, lng:props.position.longitude };
  }

  return <GoogleMap
    defaultOptions={{
      minZoom: 16,
      maxZoom: 16,
      fullscreenControl: false,
      zoomControl: false,
      streetViewControl: false,
      scaleControl: false,
      mapTypeControl: false
    }}
    defaultZoom={16}
    defaultCenter={ getCenter() }
    center={ getCenter()}
    >
    <Marker className={"ft_gm "}
            position={ getCenter() }
    />
  </GoogleMap>}
)

export default MapComponent
