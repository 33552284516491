import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import MapComponent from '../MapComponent';
import axios from 'axios';
import Geocode from "react-geocode";
import { Translate } from "react-localize-redux";
import globalTranslations from "../../utils/tr.json";
import * as Utils from "../../utils/utils.js";
import * as testUtils from "../../utils/testUtils.js";
import { renderToStaticMarkup } from "react-dom/server";
import InfoTitle from "../../components/InfoTitle"
import Loader from "../../components/Loader"


// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyDgFl8bN5Qmcf4bLbmDdUo10-dTy2G4YN0");
Geocode.enableDebug();

const TataAlarmFunction = (props) => {
    console.log("TataAlarmFunction props: ", props);
    return <TataAlarm web_token_local={props.slug}/>
}

const INITIAL_STATE = {
  userid: '', 
  tata_list: {},
  error: null,
  addingTata: false,
  loading: true
};

class TataAlarmBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.retrieveTataInput();
  } 

  getEnvironment = (web_token_local) => {
    // Dev, Stage, Prod
    console.log("Analizing:::::: " + web_token_local)
    if ( web_token_local.substring(0,1) === 'd') {
      console.log("returning Dev");
      return "Dev";
    }
    if ( web_token_local.substring(0,1) === 's') {
      console.log("returning Stage");
      return "Stage";
    }
    if ( web_token_local.substring(0,1) === 'e') {
      console.log("returning Expo");
      return "Expo";
    }
    if ( web_token_local.substring(0,1) === 'p') {
      console.log("returning Prod");
      return "Prod";
    }
    console.log("returning default: Dev");
    return "Dev";
  }

  /*
    (4, bluetooth_reason_name),
    (5, bluetooth_reason_name),
    (6, motion_reason_name),
    (7, lastupdate_reason_name),
    (12, bluetooth_reason_name),
    (13, bluetooth_reason_name),
    (14
  */

  getSampleInfoData = (web_token_local) => {
    if (web_token_local.indexOf("INFOSAMPLEBASE_R4") > -1) {
      console.log("returning INFOSAMPLEBASE_R4")
      return this.INFOSAMPLEBASE_R4;
    }
    if (web_token_local.indexOf("INFOSAMPLEBASE_R5") > -1) {
      console.log("returning INFOSAMPLEBASE_R5")
      return this.INFOSAMPLEBASE_R5;
    }
    if (web_token_local.indexOf("INFOSAMPLEBASE_R6") > -1) {
      console.log("returning INFOSAMPLEBASE_R6")
      return this.INFOSAMPLEBASE_R6;
    }
    if (web_token_local.indexOf("INFOSAMPLEBASE_R7") > -1) {
      console.log("returning INFOSAMPLEBASE_R7")
      return this.INFOSAMPLEBASE_R7;
    }
    if (web_token_local.indexOf("INFOSAMPLEBASE_R12") > -1) {
      console.log("returning INFOSAMPLEBASE_R12")
      return this.INFOSAMPLEBASE_R12;
    }
    if (web_token_local.indexOf("INFOSAMPLEBASE_R13") > -1) {
      console.log("returning INFOSAMPLEBASE_R13")
      return this.INFOSAMPLEBASE_R13;
    }
    if (web_token_local.indexOf("INFOSAMPLEBASE_R14") > -1) {
      console.log("returning INFOSAMPLEBASE_R14")
      return this.INFOSAMPLEBASE_R14;
    }
    if (web_token_local.indexOf("INFOSAMPLEBASE") > -1) {
      console.log("returning INFOSAMPLEBASE")
      return this.INFOSAMPLEBASE;
    }
    if (web_token_local.indexOf("INFOSAMPLEOLDPOS") > -1) {
      console.log("returning INFOSAMPLEOLDPOS")
      return this.INFOSAMPLEOLDPOS;
    }
    if (web_token_local.indexOf("INFOSAMPLE_SA_ERROR") > -1) {
      console.log("returning INFOSAMPLE_SA_ERROR")
      return this.INFOSAMPLE_SA_ERROR;
    }
    if (web_token_local.indexOf("INFOSAMPLENOPOS") > -1) {
      console.log("returning INFOSAMPLENOPOS")
      return this.INFOSAMPLENOPOS;
    }
    if (web_token_local.indexOf("INFOSTOPPEDALARMSAMPLE") > -1) {
      console.log("returning INFOSTOPPEDALARMSAMPLE")
      return this.INFOSTOPPEDALARMSAMPLE;
    }
    if (web_token_local.indexOf("INFOINVALIDLINK") > -1) {
      console.log("returning INFOINVALIDLINK")
      return this.INFOINVALIDLINK;
    }
    return undefined;
  }

  getSampleStoppedAlarmData = (web_token_local) => {
    if (web_token_local.indexOf("INFOSAMPLEBASE") > -1) {
      console.log("returning POSTALARMBASE")
      return {isAlarmStopped: true, res:this.POSTALARMBASE};
    }
    if (web_token_local.indexOf("INFOSAMPLE_SA_ERROR") > -1) {
      console.log("returning POSTALARMERROR")
      return {isAlarmStopped: false, res:this.POSTALARMERROR};
    }
    return undefined;
  }

  INFOSAMPLEBASE = {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"timestamp":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
  INFOSAMPLEBASE_R4 = {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":4,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"timestamp":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
  INFOSAMPLEBASE_R5 = {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":5,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"timestamp":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
  INFOSAMPLEBASE_R6 = {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":6,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"timestamp":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
  INFOSAMPLEBASE_R7 = {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":7,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"timestamp":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
  INFOSAMPLEBASE_R12 = {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":12,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"timestamp":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
  INFOSAMPLEBASE_R13 = {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":13,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"timestamp":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
  INFOSAMPLEBASE_R14 = {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":14,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"timestamp":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
  INFOSAMPLEOLDPOS = {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"timestamp":(testUtils.getRandomTimestampInHoursRange(-4,-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
  INFOSAMPLE_SA_ERROR = {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"timestamp":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
  INFOSAMPLENOPOS = {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
  INFOSTOPPEDALARMSAMPLE = {"data":{"data":{"callee_data":{"order":3,"phone":"+393272936370","role":"contact","username":"Vincenzo Roselli","uid":"-Lho_MT3eCa_mS7XiJTW","lang":"it"},"slashtag":"6038978561793430092","last_safe_time":1561035847702,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2"},"alarm_level":-1,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"timestamp":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393272936370","childname":"tata band 040","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2"}},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
  INFOINVALIDLINK = {"data":{"error":{"code":404,"message":"invalid or expired web token"}},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-232268808569908429\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}

  POSTALARMBASE = {"data":{"action":"stop_alarm","status":{"device_id":"00000988146014","web_token":"s4563233123044294865","alert_code":-1,"cause":"web token: 4563233123044294865"},"result":"ok"},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146014\",\"web_token\":\"s4563233123044294865\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
  POSTALARMERROR = {"data":{"action":"stop_alarm","status":{"device_id":"00000988146014","web_token":"s4563233123044294865","alert_code":-1,"cause":"web token: 4563233123044294865"},"result":"ok"},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146014\",\"web_token\":\"s4563233123044294865\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}

  retrieveTataInput = () => {
    var component = this;
    const web_token_local = component.props.web_token_local;
    console.log("retrieving data for: ", web_token_local);

    const body_data = {
      action: "get_stop_alarm_web_info",
      status: {"web_token": web_token_local}
    }

    const env = component.getEnvironment(web_token_local);
    axios({
        url: "https://3euiei60al.execute-api.eu-central-1.amazonaws.com/" + env + "/processupdate",
        method: 'POST',
        headers: {
          'x-api-key' : 'pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x',
          'content-type' : 'application/json'
        },
        data: body_data
      }).then((response) => { 

        console.log('retrieveTataInput response received ' + JSON.stringify(response))
        if ( this.getSampleInfoData(web_token_local) ) {
          response = this.getSampleInfoData(web_token_local);
        }
        if (response.error || response.data.error ) {
            component.setState({
              loading:false,
              web_token_local:web_token_local,
              error: response.error ? response.error : response.data.error
            }
            );
            return response.statusCode
        }

        component.setState(
          {
            info:response.data.data,
            loading:false,
            key:web_token_local
          },
          component.getWhereabouts
        );

        return response.statusCode
      })
      .catch( (error) => {
        console.log('retrieveTataInput error value', error)
        component.setState({
          loading:false,
          web_token_local:web_token_local,
          error: error
        });
      })
  }

  getCalleeName = () => {
    return this.state.info.callee_data.username ? " "+this.state.info.callee_data.username : "";
  }

  getNotifierName = () => {
    try {
      return this.state.info && this.state.info.notifier_data && this.state.info.notifier_data.username ?
      this.state.info.notifier_data.username :
      <Translate id="user._2"/>
    } catch (e) {
      console.log("getNotifierName error ", e);
      return "----"
    }
  }

  getNotifierNameOrUndefined = () => {
    try {
      return this.state.info && this.state.info.notifier_data && this.state.info.notifier_data.username ?
      this.state.info.notifier_data.username :
      undefined
    } catch (e) {
      console.log("getNotifierName error ", e);
      return "----"
    }
  }

  getWhereabouts = () => {
    var component = this;
    // Get address from latitude & longitude.
    if (component.state.info.position_data) {
      Geocode.fromLatLng(component.state.info.position_data.latitude, component.state.info.position_data.longitude).then(
        response => {
          const address = response.results[0].formatted_address;
          console.log(address);
          component.setState({address: address})
        },
        error => {
          console.error(error);
        }
      );
    }
    component.setState({address: "--"})
  }

  isLoading = () => {
    return this.state.loading
  }

  isAfterButtonPressed = () => {
    return this.isInErrorAfterStopButtonPressed() || this.isOkAfterStopButtonPressed()
  }

  isInErrorAfterStopButtonPressed = () => {
    return this.state.stoppedAlarmResponse && this.state.alarmStopped === false;
  }

  isOkAfterStopButtonPressed = () => {
    return this.state.stoppedAlarmResponse && this.state.alarmStopped === true;
  }

  isErrorOrBadFormedAlarm = () => {
    return this.state.error || this.state.info && !(this.state.info.alarm_level === parseInt(this.state.info.alarm_level))
  }

  isStoppedBySomeoneElseAlarm = () => {
    return this.state.info.alarm_level <= 0; 
  }
  isActiveAlarm = () => {
    return this.state.info.alarm_level > 0 && this.state.alarmStopped !== true; 
  }

  onStopAlarmClicked = async () => {
    const component = this;
    this.setState({"buttonLoading":true})

    // "https://europe-west1-tata-devices-dev.cloudfunctions.net/stopAlarm?key="+key+"&tataID="+tataID+"&current_user="+callee_id;

    const device_id = this.state.info.device_id;    
    const callee_id = this.state.info.callee_id; 
    const web_token_local = this.props.web_token_local; 

    const status = {
      device_id : device_id,
      current_user : callee_id,
      actor : callee_id,
      web_token : web_token_local
    }

    const body_data = {
      action: "stop_alarm",
      status: status
    }

    const env = component.getEnvironment(web_token_local);
    axios({
        url:"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/" + env + "/processupdate",
        method: 'POST',
        headers: {
          'x-api-key' : 'pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x',
          'content-type' : 'application/json'
        },
        data: body_data
      }).then((response) => {  
        console.log('onStopAlarmClicked success ' + JSON.stringify(response))
        this.setState({"buttonLoading":false})

        if ( this.props.web_token_local && this.getSampleStoppedAlarmData(this.props.web_token_local) ) {
          const ret = this.getSampleStoppedAlarmData(this.props.web_token_local);
          component.setState({alarmStopped: ret.isAlarmStopped, stoppedAlarmResponse: ret.res});
        } else {
          if (response.data) {
            component.setState( {alarmStopped: true, stoppedAlarmResponse: response} );
          }
        }
      })
      .catch( (error) => {
        console.log('onStopAlarmClicked error value', error)
        if ( this.props.web_token_local && this.getSampleStoppedAlarmData(this.props.web_token_local) ) {
          const ret = this.getSampleStoppedAlarmData(this.props.web_token_local);
          component.setState({alarmStopped: ret.isAlarmStopped, stoppedAlarmResponse: ret.res});
        } else {
          component.setState({alarmStopped: false, stoppedAlarmResponse: error});
        }
      })
  }

  renderGreetingSection = () => {
    const name = this.getCalleeName();
    return  <p>
              <Translate id="greetings.Ciao">Ciao </Translate><b>{name}</b>,
            </p>
  }

  renderGreetingSection2 = () => {
    return  <p><Translate id="greetings.Ciao2"/></p>
  }

  getDayString = (date) => {
    const todayMidnight = new Date().setHours(0,0,0,0);
    const dateMidnight = new Date(date).setHours(0,0,0,0);

    if (todayMidnight === dateMidnight) {
      return <Translate id="time.today">Oggi </Translate>;
    } else if ( todayMidnight - 86400000 === dateMidnight ) {
      return <Translate id="time.yesterday">Ieri </Translate>;
    }
    return <span><Translate id="time.onday">Il giorno </Translate> {("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth()+1)).slice(-2) + "/" + date.getFullYear() }</span>;
  }

  getStatusDescriptionWithReason = () => {
    let notifierName = "Un tuo contatto d'emergenza";
    let childname = "un bambino a lui affidato";
    try {
      notifierName = this.getNotifierName();
      childname = this.state.info.childname;
    } catch (e) {
      console.log("getStatusDescriptionWithReason define variables error: ", e);
    }

    if ([4,5,12,13].includes(this.state.info.alarm_reason)) {
      console.log("alarm_reason: " + this.state.info.alarm_reason + " --> Bluetooth")
      return (
        <div>
          <p>
            <Translate id="intro.alarm._1"/>
          </p>
          <p>
            <Translate id="intro.alarm._2"/>
            <b>{notifierName}</b>
            <Translate id="intro.alarm._3"/>
          </p>
        </div>
      )
    } else if ([6,14].includes(this.state.info.alarm_reason)) {
      console.log("alarm_reason: " + this.state.info.alarm_reason + " --> Motion")
      const withUser = this.getNotifierNameOrUndefined() ? <span><Translate id="intro.alarm._2_reason_motion_with" /> {this.getNotifierName()},</span> : ""
      return (
        <div>
          <p><Translate id="intro.alarm._1"/></p>
          <p>
            {childname}
            <Translate id="intro.alarm._2_reason_motion"/>
            {withUser}
            <Translate id="intro.alarm._3_reason_motion"/> 
          </p>
        </div>
      )
    } else if ([7].includes(this.state.info.alarm_reason)) {
      console.log("alarm_reason: " + this.state.info.alarm_reason + " --> LastUpdate")

      const part3 = this.getNotifierNameOrUndefined() ?
                    <Translate id="intro.alarm._3_reason_lastUpdate" data={{ notifierName: this.getNotifierNameOrUndefined() }}/> :
                    <Translate id="intro.alarm._3_1_reason_lastUpdate"/>
      const part4 = this.getNotifierNameOrUndefined() ?
                    <Translate id="intro.alarm._4_reason_lastUpdate" data={{ notifierName: this.getNotifierNameOrUndefined() }}/> :
                    <Translate id="intro.alarm._4_1_reason_lastUpdate"/>
      const part5 = this.getNotifierNameOrUndefined() ?
                    <Translate id="intro.alarm._5_reason_lastUpdate" data={{ notifierName: this.getNotifierNameOrUndefined() }}/> :
                    <Translate id="intro.alarm._5_1_reason_lastUpdate"/>

      return  (
        <div>
          <p>
            <Translate id="intro.alarm._2_reason_lastUpdate"/>
            {part3}
          </p>
          <p>
            {part4}
          </p>
          <p>
            {part5}
          </p>
          <p>
            <Translate id="intro.alarm._6_reason_lastUpdate"/>
          </p>
        </div>
      )
    }
    return  <div className="fc-mv-8">
              <Translate id="intro.alarm._2_reason_default"/> {childname} <Translate id="intro.alarm._3_reason_default"/> {notifierName}. <Translate id="intro.alarm._4_reason_default"/>
            </div>
  }

  renderStatusDescriptionSection = () => {

    if (this.isStoppedBySomeoneElseAlarm()) {
      const part2 = this.getNotifierNameOrUndefined() ?
        <span> <Translate id="intro.stopped._2"/> <b>{this.getNotifierNameOrUndefined()}</b>.</span> :
        "."

      return (
        <div>
          <p>
            <Translate id="intro.stopped._1"/>{part2}
          </p>
          <p>
            <Translate id="intro.stopped.thanks"/>
          </p>
        </div>
      )
    }

    var status_description =  this.getStatusDescriptionWithReason()

    return  <div>
                {status_description}
            </div>
  }

  isValidAndRecentPosition = () => {
    return  this.isValidPosition() &&
            Utils.getTimestampWithMillisecs(this.state.info.position_data.timestamp) > (parseInt(Date.now()) - 2*60*60*1000)
  }

  isValidPosition = () => {
    return  this.state.info.position_data &&
            this.state.info.position_data.latitude &&
            this.state.info.position_data.longitude &&
            this.state.info.position_data.accuracy &&
            this.state.info.position_data.timestamp
  }

  renderMapSection = () => {
    let hoursString = "--";
    let minutesString = "--";
    let posHoursString = "--";
    let posMinutesString = "--";

    try {
      const lastSafeTime = this.state.info.last_safe_time;
      const lastSafeDate = new Date(lastSafeTime);
      hoursString = ("0" + lastSafeDate.getHours()).slice(-2);
      minutesString = ("0" + lastSafeDate.getMinutes()).slice(-2);
    } catch (e) {
      console.log("renderMapSection 1 define variables error: ", e);
    }

    try {
      const lastPosTime = Utils.getTimestampWithMillisecs(this.state.info.position_data.timestamp);
      const lastPosDate = new Date(parseInt(lastPosTime));
      posHoursString = ("0" + lastPosDate.getHours()).slice(-2);
      posMinutesString = ("0" + lastPosDate.getMinutes()).slice(-2);
    } catch (e) {
      console.log("renderMapSection 2 define variables error: ", e);
    }

    const textintrotoposition1 = this.state.info.position_data ?
      <span><Translate id="map.position.intro._1"></Translate> {posHoursString}:{posMinutesString}</span> :
      "";
 
    const textintrotoposition2 = <Translate id="map.position.intro._4"/>

    const textaddressinfo = 
      this.state.info.position_data ?
      this.state.address ? this.state.address : <Translate id="map.position.info._1">Posizione segnata in mappa</Translate> :
      <Translate id="map.position.info._2">Posizione non disponibile</Translate>;

    const divAlarmActiveTime = this.isActiveAlarm() ?
    <div className={"fc-pv-8 fc-fs-12 fc-emph2 fc-color-alarm-"+this.isActiveAlarm()}>
      <Translate id="map.alarminfo"/>
      &nbsp;{hoursString}:{minutesString}&nbsp;&nbsp;
      <img className={"fc-clock"} src={"/img/clock-alarm-"+this.isActiveAlarm()+".svg"} alt="Alarm clock"/>
    </div>
    : 
    <div className={"fc-pv-8 fc-fs-12 fc-emph2 fc-color-alarm-"+this.isActiveAlarm()}>
      <Translate id="map.alarmdeactivated"/>
      &nbsp;&nbsp;
      <img className={"fc-clock"} src={"/img/clock-alarm-"+this.isActiveAlarm()+".svg"} alt="Alarm clock"/>
    </div>

    const mapComponent = this.isValidAndRecentPosition() ?
          <MapComponent className="fc-pv-8" alarmLevel={this.state.info.alarm_level} position={this.state.info.position_data} info={this.state.info}/>
          : <div className="fc-no-map"></div>

    return  <div className="fc-pt-16 fc-map-wrapper">
              <div className="fc-ph-24 fc-pb-16">
                {divAlarmActiveTime}
                <div className="fc-pv-8 fc-fs-12">{textintrotoposition2}</div>
                <div className="fc-pv-8 fc-emph2">{textaddressinfo}</div>
              </div>
              { mapComponent }  
            </div>
  }

  renderThankyouSection =() => {
    const name = this.getCalleeName();

    return  <div className="fc-ph-24 fc-fs-16">
              <div>
               <Translate id="thankyou.thankyou">Grazie</Translate> <strong>{name}</strong>,
              </div>
            </div>
  }
  
  renderAfterButtonPressedOkSection = () => {

    return  <div className="fc-ph-24">
              <p><Translate id="after.ok._1"/>!</p>
            </div>
  } 

  isButtonLoading = () => {
    return this.state.buttonLoading || false
  }

  renderContactsSection = () => {
    return  <div className="fc-ph-24 fc-pb-32 fc-fs-16">
              <Translate id="after.ko._5">Se il problema persiste non ti preoccupare, </Translate>
              <a className="fc-blue fc-emph2 fc-td-u" href="mailto:support@filotrack.com">
                <Translate id="after.ko._6">contattaci</Translate></a> <Translate id="after.ko._7">e lo risolveremo subito.</Translate>
            </div>
  }

  renderStopButtonSection = () => {
    const introText = this.isAfterButtonPressed() ?
                      "" :
                      <div><p><Translate id="button._2"/></p><p><Translate id="button._3"/></p></div>;

    const loading = this.isButtonLoading() && !this.isAfterButtonPressed()
    if ( loading ) {
      return  <div className="fc-button-wrapper fc-pb-32 fc-ph-24 fc-pt-8 fc-fs-16">
                  {introText}
                  <div className={"fc-pv-8"}>
                    <button disabled="disabled" className={"fc-button fc-ta-c fc-color-alarm-"+this.isActiveAlarm()} onClick={() => {this.onStopAlarmClicked()}}><img className="fc-spinner-loading" src="../img/fc-spinner.png"/></button>
                  </div>
              </div>
    }

    return  <div className="fc-button-wrapper fc-pb-32 fc-ph-24 fc-pt-8 fc-fs-16">
                {introText}
                <div className={"fc-pv-8"}>
                  <button className={"fc-button fc-ta-c fc-color-alarm-"+this.isActiveAlarm()} onClick={() => {this.onStopAlarmClicked()}}><Translate id="button.buttontext"/></button>
                </div>
            </div>
  }

  render() {
    /* 1 Error - Stop Alarm wrong/epired link Case */
    /* If the link is invalid, 404 is sent by processUpdate --> the app show a not found layout */
    /* At the bottom, if an other kind of error occurs, it is reported */
    if (this.isErrorOrBadFormedAlarm()) {
      return  <div className="fc-p-24">
                <Translate>
                  {({ translate }) => <InfoTitle text={translate("context.em_contact")}/>}
                </Translate>
                <p>
                  <Translate id="greetings.Ciao2"/>
                </p>
                <p>
                  <Translate id="greetings.inactivelink"/>
                </p>
                { this.state.error && !(this.state.error.code === 404) &&
                  <p className={"fc-error"}>
                    {this.state.error.message}
                  </p>
                }
              </div>
    }

    /* 2 Loading ... */
    if (this.isLoading()) {
      return <Loader type="text"/>
    }

    /* 3 Stop Alarm - After button pressed - error */
    if (this.isInErrorAfterStopButtonPressed()) {
      return (
        <div>
          <div className="fc-p-24"> 
            <Translate>
              {({ translate }) => <InfoTitle text={translate("context.ops")}/>}
            </Translate>
            { this.renderGreetingSection() }
            <span><Translate id="after.ko._3"/></span><br/>
            <span><Translate id="after.ko._3_1"/></span><br/>
            <span><Translate id="after.ko._4"/></span>
          </div>
          <div className="fc-bg-lightblue">
            { this.renderMapSection() }
          </div>
          { this.renderStopButtonSection() }
          {this.state.error && <p>{this.state.error.message}</p>}
        </div>
      );
    }

    /* 4 Stop Alarm - After button pressed - ok */
    if (this.isOkAfterStopButtonPressed()) {
      console.log("------ isOkAfterStopButtonPressed")
      return (
        <div className="fc-pt-24"> 
          { this.renderThankyouSection() }
          { this.renderAfterButtonPressedOkSection() }
          <div className="fc-bg-lightblue">
            { this.renderMapSection() }
          </div>
          {this.state.error && <p>{this.state.error.message}</p>}
        </div>
      );
    }

    /* 5 Stop Alarm - Alarm already stopped Case */
    if (this.isStoppedBySomeoneElseAlarm()) {
      return (
        <div className="fc-p-24"> 
          {/*<pre className="fc-prod-hide">Stop Alarm - Alarm already stopped Case</pre>*/}
          <Translate>
            {({ translate }) => <InfoTitle text={translate("context.em_contact")}/>}
          </Translate>
          <div className={"fc-intro__90"}>
            { this.renderGreetingSection() }
            { this.renderStatusDescriptionSection() }
          </div>
          {this.state.error && <p>{this.state.error.message}</p>}
        </div>
      );
    } 
 
    /* 6 Stop Alarm - Alarm active Base Case */
    return (
      <div>
        <div className={"fc-intro__w90 fc-p-24"}>
          <Translate>
            {({ translate }) => <InfoTitle text={translate("context.em_contact")}/>}
          </Translate>
          { this.renderGreetingSection() }
          { this.renderStatusDescriptionSection() }
        </div>
        <div className="fc-bg-lightblue">
          { this.renderMapSection() }
        </div>
        <div className={"fc-mb-40"}>
        { this.renderStopButtonSection() }
        </div>
        <div className={"fc-mb-40"}>        
          {this.state.error && <p>{this.state.error.message}</p>}
        </div>
      </div>
    );

  }
}

const TataAlarm = withFirebase(TataAlarmBase);

export default TataAlarmFunction;
