import * as testUtils from '../utils/testUtils'

const stubTataInfo = {
	"INFOSAMPLEBASE_B" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_E" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R4_B" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":4,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R4_E" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":4,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R5_B" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":5,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R5_E" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":5,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R6_B" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":6,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R6_E" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":6,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R7_B" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":7,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R7_E" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":7,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R7_NOPOS_B" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":7,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-4, -2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R7_NOPOS_E" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":7,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-4, -2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R12_B" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":12,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R12_E" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":12,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R13_B" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":13,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R13_E" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":13,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R14_B" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":14,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R14_E" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"alarm_reason":14,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEOLDPOS_B" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-4,-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEOLDPOS_E" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-4,-2))},"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLENOPOS_B" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLENOPOS_E" : {"data":{"data":{"callee_data":{"order":1,"phone":"+393479024692","role":"user","username":"Vincenzo Roselli","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"slashtag":"-2322688085699084292","last_safe_time":1561035039609,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2","lang":"it"},"alarm_level":2,"phone":"+393479024692","childname":"tata band 040"}},"status":200,"statusText":"OK","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSTOPPEDALARMSAMPLE" : {"data":{"data":{"callee_data":{"order":3,"phone":"+393272936370","role":"contact","username":"Vincenzo Roselli","uid":"-Lho_MT3eCa_mS7XiJTW","lang":"it"},"slashtag":"6038978561793430092","last_safe_time":1561035847702,"session_id":32,"device_id":"00000988146040","notifier_data":{"order":1,"phone":"+393479024692","role":"user","username":"Stefania","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2"},"alarm_level":-1,"position_data":{"latitude":41.82232647599398,"longitude":12.459573845309658,"accuracy":65,"lastUpdate":(testUtils.getRandomTimestampInHoursRange(-2))},"phone":"+393272936370","childname":"tata band 040","uid":"lRMBTBVBefOZ8vX3LcorYA2LwvZ2"}},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-2322688085699084292\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOINVALIDLINK" : {"data":{"error":{"code":404,"message":"invalid or expired slashtag"}},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"get_stop_alarm_web_info\",\"status\":{\"web_token\":\"s-232268808569908429\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}}
}

export default stubTataInfo