/* LanguageSelector using setActiveLanguage: */

import React from "react";
import { withLocalize } from "react-localize-redux";

const LanguageToggle = (props) => (
  <div className="selector">
    {props.languages.map(lang => (
	    <button key={lang.code} className="fc-lang-sel-button fc-blue" onClick={() => props.setActiveLanguage(lang.code)}>
	      {lang.code}
	    </button>
    ))}
  </div>
);

export default withLocalize(LanguageToggle);