export function getRandomTimestampInHoursRange(startHours = 0, stopHours = 0) {
  // const n = Date.now()
  const start = Date.now() + (startHours * 3600 * 1000 - 1)
  const stop = Date.now() + (stopHours * 3600 * 1000 - 2)

  return getRandomTimestamp(start, stop)
}

export function getRandomTimestamp(start, stop) {
  start = start ?  getTimestampWithMillisecs(start) : Date.now();
  stop = stop ?  getTimestampWithMillisecs(stop) : Date.now();
  return Math.floor(Math.random() * (stop - start)) + start;
}

export function getTimestampWithMillisecs(timestamp) {
  timestamp = parseInt(timestamp)
  if (timestamp < 1561000000000) {
    return timestamp * 1000
  }
  return timestamp;
}