import React, { Component } from 'react';

import LanguageSelector from '../../components/LanguageSelector';


const Header = () => (
  <div className="fc-footer fc-ta-c fc-pt-24">
    <LanguageSelector/>
  </div>
);



export default Header; 
