import React, {Component, Fragment} from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { LocalizeProvider } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";

import globalTranslations from "../../utils/tr.json";
import * as Utils from "../../utils/utils.js";

import StopAlarm from '../../pages/StopAlarm';
import StopAlarmPage from '../../pages/StopAlarmPage';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import * as ROUTES from '../../constants/routes';

class AppBase extends Component {
  constructor(props) {
    super(props);

    // set languages and translations for the whole app
    this.props.initialize({
      languages: Utils.languageList,
      translation: globalTranslations,
      options: { renderToStaticMarkup, renderInnerHtml: true }
    })
    this.props.setActiveLanguage(Utils.getBrowserLangOrDefault())
  }

  render() {
    return (
      <Fragment>
      	<Header/> 
		    <Router>
          <Switch>
            <Route path={ROUTES.STOPALARMPAGE} component={StopAlarmPage} />
            <Route path={ROUTES.STOPALARM} component={StopAlarmPage} />
            <Redirect from="/404" to="/stopalarm/invalidlink" />
            <Redirect from="/" to="/stopalarm/invalidlink" />
          </Switch>
        </Router>
        <Footer/> 
      </Fragment>
    );

  }
}

const AppComposed = withLocalize(AppBase)

const App = () => (
    <LocalizeProvider>
      <AppComposed/>
    </LocalizeProvider>
);

export default App;