import React from 'react'
import { Translate } from "react-localize-redux";

import * as Checker from '../../utils/checker'

const ButtonSection = (props) => {
  const {info, stopAlarmInfo, buttonClicked, loading} = props

  return (
    <div className="fc-button-wrapper fc-pb-32 fc-ph-24 fc-pt-8 fc-fs-16">
      {/* if the alarm was stopped or there was an error (if it is the second time you see the button) */}
      {stopAlarmInfo.alarm_stopped || stopAlarmInfo.error ? "" : <div><p><Translate id="button.intro.warning"/><br/><Translate id="button.intro.explaining"/></p></div>}
      <div className={"fc-pv-8"}>
        { stopAlarmInfo.loading ?
          <button disabled="disabled" className={"fc-button fc-ta-c fc-color-alarm-true"} onClick={() => {buttonClicked()}}><img className="fc-spinner-loading" src="../img/fc-spinner.png"/></button> :
          <button className={"fc-button fc-ta-c fc-color-alarm-true"} onClick={() => {buttonClicked()}}><Translate id="button.buttontext"/></button>
        }
      </div>
      {stopAlarmInfo.error ? <p><Translate id="button.wrong.contact_us"/></p> : ""}
    </div>
  )
}

export default ButtonSection