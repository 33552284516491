import * as testUtils from '../utils/testUtils'

const stubStopAlarmResponse = {
	"INFOSAMPLEBASE_B" : {"data":{"action":"stop_alarm","status":{"device_id":"00000988146014","web_token":"s4563233123044294865","alert_code":-1,"cause":"web token: 4563233123044294865"},"result":"ok"},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146014\",\"web_token\":\"s4563233123044294865\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_E" : {"error":{"message":"Network Error","name":"Error","stack":"Error: Network Error\n    at createError (http://localhost:3000/static/js/0.chunk.js:32051:15)\n    at XMLHttpRequest.handleError (http://localhost:3000/static/js/0.chunk.js:31546:14)","config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146040\",\"web_token\":\"sINFOSAMPLEBASE_R4_E\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1}}},
	"INFOSAMPLEBASE_R4_B" : {"data":{"action":"stop_alarm","status":{"device_id":"00000988146014","web_token":"s4563233123044294865","alert_code":-1,"cause":"web token: 4563233123044294865"},"result":"ok"},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146014\",\"web_token\":\"s4563233123044294865\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R4_E" :  {"error":{"message":"Network Error","name":"Error","stack":"Error: Network Error\n    at createError (http://localhost:3000/static/js/0.chunk.js:32051:15)\n    at XMLHttpRequest.handleError (http://localhost:3000/static/js/0.chunk.js:31546:14)","config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146040\",\"web_token\":\"sINFOSAMPLEBASE_R4_E\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1}}},
	"INFOSAMPLEBASE_R5_B" : {"data":{"action":"stop_alarm","status":{"device_id":"00000988146014","web_token":"s4563233123044294865","alert_code":-1,"cause":"web token: 4563233123044294865"},"result":"ok"},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146014\",\"web_token\":\"s4563233123044294865\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R5_E" :  {"error":{"message":"Network Error","name":"Error","stack":"Error: Network Error\n    at createError (http://localhost:3000/static/js/0.chunk.js:32051:15)\n    at XMLHttpRequest.handleError (http://localhost:3000/static/js/0.chunk.js:31546:14)","config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146040\",\"web_token\":\"sINFOSAMPLEBASE_R4_E\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1}}},
	"INFOSAMPLEBASE_R6_B" : {"data":{"action":"stop_alarm","status":{"device_id":"00000988146014","web_token":"s4563233123044294865","alert_code":-1,"cause":"web token: 4563233123044294865"},"result":"ok"},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146014\",\"web_token\":\"s4563233123044294865\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R6_E" : {"error":{"message":"Network Error","name":"Error","stack":"Error: Network Error\n    at createError (http://localhost:3000/static/js/0.chunk.js:32051:15)\n    at XMLHttpRequest.handleError (http://localhost:3000/static/js/0.chunk.js:31546:14)","config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146040\",\"web_token\":\"sINFOSAMPLEBASE_R4_E\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1}}},
	"INFOSAMPLEBASE_R7_B" : {"data":{"action":"stop_alarm","status":{"device_id":"00000988146014","web_token":"s4563233123044294865","alert_code":-1,"cause":"web token: 4563233123044294865"},"result":"ok"},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146014\",\"web_token\":\"s4563233123044294865\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R7_E" : {"error":{"message":"Network Error","name":"Error","stack":"Error: Network Error\n    at createError (http://localhost:3000/static/js/0.chunk.js:32051:15)\n    at XMLHttpRequest.handleError (http://localhost:3000/static/js/0.chunk.js:31546:14)","config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146040\",\"web_token\":\"sINFOSAMPLEBASE_R4_E\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1}}},
	"INFOSAMPLEBASE_R7_NOPOS_B" : {"data":{"action":"stop_alarm","status":{"device_id":"00000988146014","web_token":"s4563233123044294865","alert_code":-1,"cause":"web token: 4563233123044294865"},"result":"ok"},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146014\",\"web_token\":\"s4563233123044294865\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R7_NOPOS_E" : {"error":{"message":"Network Error","name":"Error","stack":"Error: Network Error\n    at createError (http://localhost:3000/static/js/0.chunk.js:32051:15)\n    at XMLHttpRequest.handleError (http://localhost:3000/static/js/0.chunk.js:31546:14)","config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146040\",\"web_token\":\"sINFOSAMPLEBASE_R4_E\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1}}},
	"INFOSAMPLEBASE_R12_B" : {"data":{"action":"stop_alarm","status":{"device_id":"00000988146014","web_token":"s4563233123044294865","alert_code":-1,"cause":"web token: 4563233123044294865"},"result":"ok"},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146014\",\"web_token\":\"s4563233123044294865\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R12_E" : {"error":{"message":"Network Error","name":"Error","stack":"Error: Network Error\n    at createError (http://localhost:3000/static/js/0.chunk.js:32051:15)\n    at XMLHttpRequest.handleError (http://localhost:3000/static/js/0.chunk.js:31546:14)","config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146040\",\"web_token\":\"sINFOSAMPLEBASE_R4_E\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1}}},
	"INFOSAMPLEBASE_R13_B" : {"data":{"action":"stop_alarm","status":{"device_id":"00000988146014","web_token":"s4563233123044294865","alert_code":-1,"cause":"web token: 4563233123044294865"},"result":"ok"},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146014\",\"web_token\":\"s4563233123044294865\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R13_E" : {"error":{"message":"Network Error","name":"Error","stack":"Error: Network Error\n    at createError (http://localhost:3000/static/js/0.chunk.js:32051:15)\n    at XMLHttpRequest.handleError (http://localhost:3000/static/js/0.chunk.js:31546:14)","config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146040\",\"web_token\":\"sINFOSAMPLEBASE_R4_E\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1}}},
	"INFOSAMPLEBASE_R14_B" : {"data":{"action":"stop_alarm","status":{"device_id":"00000988146014","web_token":"s4563233123044294865","alert_code":-1,"cause":"web token: 4563233123044294865"},"result":"ok"},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146014\",\"web_token\":\"s4563233123044294865\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEBASE_R14_E" : {"error":{"message":"Network Error","name":"Error","stack":"Error: Network Error\n    at createError (http://localhost:3000/static/js/0.chunk.js:32051:15)\n    at XMLHttpRequest.handleError (http://localhost:3000/static/js/0.chunk.js:31546:14)","config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146040\",\"web_token\":\"sINFOSAMPLEBASE_R4_E\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1}}},
	"INFOSAMPLEOLDPOS_B" : {"data":{"action":"stop_alarm","status":{"device_id":"00000988146014","web_token":"s4563233123044294865","alert_code":-1,"cause":"web token: 4563233123044294865"},"result":"ok"},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146014\",\"web_token\":\"s4563233123044294865\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLEOLDPOS_E" : {"error":{"message":"Network Error","name":"Error","stack":"Error: Network Error\n    at createError (http://localhost:3000/static/js/0.chunk.js:32051:15)\n    at XMLHttpRequest.handleError (http://localhost:3000/static/js/0.chunk.js:31546:14)","config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146040\",\"web_token\":\"sINFOSAMPLEBASE_R4_E\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1}}},
	"INFOSAMPLENOPOS_B" : {"data":{"action":"stop_alarm","status":{"device_id":"00000988146014","web_token":"s4563233123044294865","alert_code":-1,"cause":"web token: 4563233123044294865"},"result":"ok"},"status":200,"statusText":"","headers":{"content-type":"application/json"},"config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146014\",\"web_token\":\"s4563233123044294865\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"request":{}},
	"INFOSAMPLENOPOS_E" : {"error":{"message":"Network Error","name":"Error","stack":"Error: Network Error\n    at createError (http://localhost:3000/static/js/0.chunk.js:32051:15)\n    at XMLHttpRequest.handleError (http://localhost:3000/static/js/0.chunk.js:31546:14)","config":{"url":"https://3euiei60al.execute-api.eu-central-1.amazonaws.com/Stage/processupdate","method":"post","data":"{\"action\":\"stop_alarm\",\"status\":{\"device_id\":\"00000988146040\",\"web_token\":\"sINFOSAMPLEBASE_R4_E\"}}","headers":{"Accept":"application/json, text/plain, */*","Content-Type":"application/json","x-api-key":"pupK0nq6w19w7kmaaZLX38ENe3h2nTSj3t41xv1x"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1}}}
}

export default stubStopAlarmResponse


